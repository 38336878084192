.zone-nav-button-height {
    height: calc(18vh)
}

/** loading-animation */
.loader-ata {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .loader-ata div {
    position: absolute;
    left: 8px;
    width: 16px;
    animation: loader-ata 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .loader-ata div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .loader-ata div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .loader-ata div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes loader-ata {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }
  
/** drawer background animation */
.fade-in {
  -webkit-animation: fadein .2s linear forwards;
  animation: fadein .2s linear forwards;
  background: #000;
}
.fade-out {
    -webkit-animation: fadeout .2s linear forwards;
    animation: fadeout .2s linear forwards;
    background: #000;
}

@-webkit-keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 0.6; }
}
  
@keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 0.6; }
}
  
@-webkit-keyframes fadeout {
  0% { opacity: 0.6; }
  100% { opacity: 0; }
}
  
@keyframes fadeout {
  0% { opacity: 0.6; }
  100% { opacity: 0; }
}

.center-fixed {
  transform: translate(-50%, 0);
}