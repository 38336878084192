.dark {
  --color-bg-primary: #2d3748;
  --color-bg-secondary: #283141;
  --color-text-primary: #f7fafc;
  --color-text-secondary: #e2e8f0;
  --color-text-accent: #81e6d9;
}

.light {
  --color-bg-primary: #ffffff;
  --color-bg-secondary: #edf2f7;
  --color-text-primary: #2d3748;
  --color-text-secondary: #4a5568;
  --color-text-accent: #2b6cb0;
}

:root {
  @apply light;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  -webkit-background-clip: text !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #36413E;
  -webkit-text-fill-color: #36413E;
  -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset;
  transition: background-color 5000s ease-in-out 0s;
}
.dark input:-webkit-autofill,
.dark input:-webkit-autofill:hover, 
.dark nput:-webkit-autofill:focus,
.dark textarea:-webkit-autofill,
.dark textarea:-webkit-autofill:hover,
.dark textarea:-webkit-autofill:focus,
.dark select:-webkit-autofill,
.dark select:-webkit-autofill:hover,
.dark select:-webkit-autofill:focus {
  border: 1px solid #F6F4F4;
  -webkit-text-fill-color: #F6F4F4;
  -webkit-box-shadow: 0 0 0px 1000px #645454 inset;
  transition: background-color 5000s ease-in-out 0s;
}